/**
 * Created by osirvent on 05/06/2015.
 */
angular.module('annexaApp')
    .factory('ModalConfigConflict',['$http', '$translate', '$compile', '$state', 'apiSign', 'SignService', '$rootScope', 'RestService', function($http, $translate, $compile, $state, apiSign, SignService, $rootScope, RestService) {
        var ModalConfigConflict = function($scope, module) {
            this.initModal($scope);
        };

        //-- Inici funcions modals --//
        ModalConfigConflict.prototype.initModal = function ($scope){
            $scope.signConfigUser = {id:'', user:{id:$rootScope.LoggedUser.id}, position:3, firstPage:true, typeConflict:'SEGUENT_NO_HORARIA', createdDate:''};
            if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.signConflictsConfiguration){
                $scope.signConfigUser.position = (($rootScope.app.configuration.sign_default_config_user.value)?$rootScope.app.configuration.sign_default_config_user.value:3);
                $scope.signConfigUser.firstPage = (($rootScope.app.configuration.signConflictsConfiguration.firstPage != undefined)?$rootScope.app.configuration.signConflictsConfiguration.firstPage:true);
                $scope.signConfigUser.typeConflict = (($rootScope.app.configuration.signConflictsConfiguration.typeConflict)?$rootScope.app.configuration.signConflictsConfiguration.typeConflict:'SEGUENT_NO_HORARIA');
            }
            RestService.findByUrl(apiSign.getSignConfigUserById+"?id="+$rootScope.LoggedUser.id).then(function(dataSearch){
                if (dataSearch.data){
                    var data = JSOG.decode(dataSearch.data);
                    $scope.signConfigUser.user = {id:data.user.id};
                    $scope.signConfigUser.position = data.position;
                    $scope.signConfigUser.firstPage = data.firstPage;
                    $scope.signConfigUser.typeConflict = data.typeConflict;
                    $scope.signConfigUser.id = data.id;
                    $scope.signConfigUser.createdDate = data.createdDate;
                }
            }).catch(function(){
            });
        };

        ModalConfigConflict.prototype.eliminarDadesScope = function ($scope){
            this.initModal($scope);
        };

        ModalConfigConflict.prototype.crearConfig = function ($scope){
            if($scope.signConfigUser.id) {
                $scope.signConfigUser.createdDate = new Date();
            }
            SignService.insertOrUpdateConfig(apiSign.getSignConfigUser,$scope.signConfigUser).then(function (dataUpdate) {
                if(dataUpdate){
                    $scope.signConfigUser = JSOG.decode(dataUpdate);
                }
            }).catch(function () {
            });
        };
        //-- Fi funcions modals--//
        return ModalConfigConflict;
    }]);